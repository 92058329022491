import React from 'react'
import axios from 'axios'
import { RouteComponentProps } from '@reach/router'

import { useMainQuery } from '../generated/graphql'
import ChartList from '../components/ChartList'
import Container from '../components/ui/Container'
// import { baseUrl, barsWithLatestQuote } from '../helpers'

axios.defaults.withCredentials = true

// type GroupedPositions = {
//   [index: string]: AccountPosition[] | undefined
// }

// const symbolType = (type: string, positionGroups: GroupedPositions): string[] =>
//   R.map<AccountPosition, string>(
//     p => p.instrument.symbol,
//     R.propOr([], type, positionGroups),
//   )

// const symbolsFromPositions = (
//   positions: AccountPosition[],
// ): {
//   equity: string[]
//   option: string[]
//   underlying: string[]
// } => {
//   const groupedPositions: GroupedPositions = R.groupBy(
//     (position: AccountPosition) => position.instrument.assetType,
//     positions,
//   )
//   const equitySymbols = [...new Set(symbolType('EQUITY', groupedPositions))]
//   const optionSymbols = [...new Set(symbolType('OPTION', groupedPositions))]
//   const underlyingSymbols = [
//     ...new Set(
//       R.map<AccountPosition, string>(
//         ({ instrument }) => (instrument as OptionInstrument).underlyingSymbol,
//         R.propOr([], 'OPTION', groupedPositions),
//       ),
//     ),
//   ]
//   return {
//     equity: equitySymbols,
//     option: optionSymbols,
//     underlying: underlyingSymbols,
//   }
// }

// const query = (
//   endpoint: string,
//   symbols: string[],
//   params: object = {},
// ): any => {
//   if (symbols && symbols.length > 0) {
//     return axios.get(baseUrl() + endpoint, {
//       params: { ...params, symbols: symbols.join() },
//       withCredentials: true,
//     })
//   } else {
//     return new Promise(resolve => resolve())
//   }
// }

// const collectData = async () => {
//   // TODO: This all needs redesign to account for no positions
//   const accounts = (await axios.get(baseUrl() + '/positions', {
//     withCredentials: true,
//   })).data
//   // R.reduce((symbolList: string[], positions: Position[]) => {
//   //   symbolList.push
//   // }, [], R.values(accounts)
//   const positions = R.flatten(R.values(accounts))
//   const symbols = symbolsFromPositions(positions)
//   const symbolsFlat = Object.values(symbols).flat()
//   const barSymbols = [...symbols.underlying, ...symbols.equity]

//   const quotesPromise = query('/quote', symbolsFlat)
//   const barsPromise = query('/bars', barSymbols, { barCount: 100 })
//   const optionValuePromise = query('/option-value', symbols.underlying)

//   const quotes = (await quotesPromise).data
//   const bars = (await barsPromise).data
//   const updatedBars = barsWithLatestQuote(bars, quotes)
//   return {
//     positions: positions,
//     quotes: quotes,
//     bars: updatedBars,
//     optionValue: (await optionValuePromise).data,
//     symbols,
//   }
// }

const Layout = (props: RouteComponentProps) => {
  // const [data, setData] = useState()
  const { data, loading } = useMainQuery({ onError: console.error })
  // useEffect(() => {
  //   collectData().then(setData)
  // }, [])
  console.info(`loading: ${loading} data: ` + JSON.stringify(data, null, 2))
  return (
    <Container>
      <header className="App-header">Your positions</header>
      {loading ? <div>Loading...</div> : <ChartList data={data!} />}
    </Container>
  )
}

export default Layout
