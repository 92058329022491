/* eslint-disable no-console */
import React, { useContext, useState } from 'react'
import useForm from 'react-hook-form'
import axios from 'axios'
import { RouteComponentProps, Redirect } from '@reach/router'
import { UserContext } from '../contexts'
import { baseUrl } from '../helpers'

type FormData = {
  email: string
  password: string
}

type LoginProps = RouteComponentProps

axios.defaults.withCredentials = true

const Login = (props: LoginProps) => {
  const { register, handleSubmit, errors } = useForm<FormData>()
  const [badCredentials, setBadCredentials] = useState(false)
  const userContext = useContext(UserContext)
  const onSubmit = (data: FormData) => {
    axios
      .post(baseUrl() + '/user/login', data, {
        withCredentials: true,
        headers: { crossDomain: true, 'Content-Type': 'application/json' },
      })
      .then(response => userContext.setUser(response.data))
      .catch(err => {
        if (err.response && err.response.status === 401)
          return setBadCredentials(true)
        console.error(err)
      })
  }
  if (userContext.user) {
    return <Redirect to="/" noThrow />
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label>Email:</label>
        <input type="text" name="email" ref={register({ required: true })} />
        {errors.email && <span>This field is required</span>}
      </div>
      <div>
        <label>Password:</label>
        <input
          type="password"
          name="password"
          ref={register({ required: true })}
        />
        {errors.password && <span>This field is required</span>}
      </div>
      <div>
        <input type="submit" value="Log In" />
      </div>
      {badCredentials && <div>Invalid credentials</div>}
    </form>
  )
}

export default Login
