import React, { useRef, useEffect } from 'react'
import moment from 'moment-timezone'
// import { DataFrame } from 'data-forge'
import { ChartCanvas, Chart } from '@btorresgil/react-financial-charts'
import { CandlestickSeries } from '@btorresgil/react-financial-charts/lib/series'
import { XAxis, YAxis } from '@btorresgil/react-financial-charts/lib/axes'
import {
  CrossHairCursor,
  MouseCoordinateX,
  MouseCoordinateY,
} from '@btorresgil/react-financial-charts/lib/coordinates'
import { discontinuousTimeScaleProvider } from '@btorresgil/react-financial-charts/lib/scale'
// import { OHLCTooltip } from '@btorresgil/react-financial-charts/lib/tooltip'
import { last } from '@btorresgil/react-financial-charts/lib/utils'
import { toCurrency } from '../helpers'
import { Bar } from '../generated/graphql'

export interface IEventHandler {
  (type: string, props: object, state: object, e?: Event): void
}

interface IChartCanvas {
  subscribe: (id: string, rest: object) => void
  unsubscribe: (id: string) => void
  state: {
    chartConfig: object
  }
}

export interface StockChartProps {
  symbol: string
  bars: Bar[]
  quote: number
  height?: number
  width?: number
  ratio?: number
  eventHandler?: IEventHandler
}

const StockChart = ({
  symbol,
  bars,
  quote, // TODO: Use quote
  height = 400,
  width = 800,
  ratio = 1,
  eventHandler,
}: StockChartProps) => {
  const chartCanvasRef = useRef<ChartCanvas>(null)
  useEffect(() => {
    const canvas = chartCanvasRef.current
    if (!canvas || !eventHandler) return
    canvas.subscribe('chart-events', {
      listener: eventHandler,
    })
    eventHandler(
      'mount',
      { chartConfig: chartCanvasRef.current!.state.chartConfig },
      {},
    )
    return () => {
      if (!canvas || !eventHandler) return
      canvas.unsubscribe('chart-events')
    }
  }, [eventHandler, chartCanvasRef])
  const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor(
    (d: Bar) => moment(d.date).toDate(),
  )
  const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(bars)
  const barCount = width ? Math.floor(width / 15) : 100
  return (
    <ChartCanvas
      ratio={ratio}
      width={width}
      height={height}
      margin={{ left: 0, right: 50, top: 0, bottom: 30 }}
      padding={8}
      type="hybrid"
      pointsPerPxThreshold={1}
      seriesName={symbol}
      data={data}
      xAccessor={xAccessor}
      xScale={xScale}
      displayXAccessor={displayXAccessor}
      // xScaleProvider={discontinuousTimeScaleProvider}
      xExtents={[
        xAccessor(last(data)),
        xAccessor(data[data.length - barCount]),
      ]}
      mouseMoveEvent={true}
      panEvent={true}
      zoomEvent={false}
      clamp="right"
      ref={chartCanvasRef}
    >
      <Chart id={1} yExtents={(d: Bar) => [d.high, d.low]}>
        <XAxis axisAt="bottom" orient="bottom" ticks={5} />
        <YAxis
          axisAt="right"
          orient="right"
          // tickInterval={5}
          // tickValues={[40, 60]}
          ticks={5}
        />
        <MouseCoordinateX
          at="bottom"
          orient="bottom"
          displayFormat={(time: Date | string) => moment(time).format('l')}
        />
        <MouseCoordinateY
          at="right"
          orient="right"
          displayFormat={toCurrency}
        />
        <CandlestickSeries clip={false} />
      </Chart>

      <CrossHairCursor />
    </ChartCanvas>
  )
}

export default React.memo(StockChart)
