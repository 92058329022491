/* eslint-disable no-console */
import React, { useState } from 'react'
import { ApolloError } from 'apollo-boost'
import { UserContext } from './contexts'
import Layout from './pages/Layout'
import Protected from './pages/Protected'
import { useProfileQuery } from './generated/graphql'
import Login from './pages/Login'
import { Router } from '@reach/router'

declare module 'apollo-boost' {
  interface ApolloError {
    networkError: Error & { result?: { error: string } } | null
  }
}

const profileError = (err: ApolloError) => {
  if (
    err.networkError &&
    err.networkError.result &&
    err.networkError.result.error === 'no-user'
  ) {
    console.log('no-user')
  } else {
    console.error(err)
  }
}

const App = () => {
  const [user, setUser] = useState<object | null | undefined>()
  console.log('render app component')
  const { loading, error } = useProfileQuery({
    // onCompleted: data => data !== undefined && setUser(data),
    // onError: (error: ApolloError) => profileError(error, setUser),
    onCompleted: setUser,
    fetchPolicy: 'network-only',
  })
  if (loading) {
    return <div>Loading...</div>
  }
  if (error) {
    profileError(error)
  }
  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Router>
        <Protected path="/">
          <Layout path="/" />
        </Protected>
        <Login path="/login" />
      </Router>
    </UserContext.Provider>
  )
}

export default App
