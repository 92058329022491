import React, { useContext } from 'react'
import { RouteComponentProps, Redirect } from '@reach/router'
import { UserContext } from '../contexts'

type ProtectedProps = {
  children: React.ReactNode
} & RouteComponentProps

const Protected = ({ children }: ProtectedProps) => {
  const userContext = useContext(UserContext)
  return userContext.user ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <Redirect to="/login" noThrow />
  )
}

export default Protected
