import React from 'react'
import { Account, MainQuery } from '../generated/graphql'
import StockChart from './StockChart'
import StockChartWithRisk from './StockChartWithRisk'
import useBounds from '../hooks/useBounds'

type ChartListProps = {
  data: MainQuery
  // data: {
  // accounts: Account[]
  // quotes: { [symbol: string]: Quote }
  // bars: { [symbol: string]: ChartBar[] }
  // optionValue: { [symbol: string]: AccountPosition[] }
  // symbols: {
  //   equity: string[]
  //   option: string[]
  //   underlying: string[]
  // }
  // }
}

const ChartList = ({ data }: ChartListProps) => {
  const devicePixelRatio = window.devicePixelRatio || 1
  const [bounds, boundsRef] = useBounds<HTMLDivElement>()
  const positions = data.accounts.map(account => account.positions).flat()
  const equityPositions = positions.filter(
    position => position.instrument.assetType === 'Equity',
  )
  return (
    <div ref={boundsRef}>
      {!('width' in bounds) ? (
        <div />
      ) : (
        <>
          {/* <div id="options-chart-list">
            {data.symbols.underlying.map(symbol => (
              <div key={'option-' + symbol}>
                <div>{symbol}</div>
                <StockChartWithRisk
                  symbol={symbol}
                  bars={data.bars[symbol]}
                  quote={data.quotes[symbol] as EquityQuote}
                  width={'width' in bounds ? bounds.width : 500}
                  ratio={devicePixelRatio}
                  optionStats={data.optionValue[symbol]}
                />
              </div>
            ))}
          </div> */}
          <div id="equity-chart-list">
            {equityPositions.map(position => (
              <div key={'equity-' + position.instrument.symbol}>
                <div>{position.instrument.symbol}</div>
                <StockChart
                  symbol={position.instrument.symbol}
                  bars={position.instrument.bars}
                  quote={position.instrument.quote.lastPrice}
                  width={'width' in bounds ? bounds.width : 500}
                  ratio={devicePixelRatio}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default ChartList
