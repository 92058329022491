import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** Mongo object id scalar type */
  ObjectId: any,
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: Date,
};

export type Account = {
   __typename?: 'Account',
  _id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  type: AccountType,
  accountNumber: Scalars['String'],
  currentBalance: Scalars['Float'],
  cashBuyingPower: Scalars['Float'],
  positions: Array<Position>,
};

export enum AccountType {
  Cash = 'Cash',
  Margin = 'Margin'
}

export enum AssetType {
  Equity = 'Equity',
  Option = 'Option',
  Index = 'Index',
  MutualFund = 'MutualFund',
  Cash = 'Cash',
  FixedIncome = 'FixedIncome',
  Currency = 'Currency'
}

export type Bar = {
   __typename?: 'Bar',
  date: Scalars['DateTime'],
  open: Scalars['Float'],
  high: Scalars['Float'],
  low: Scalars['Float'],
  close: Scalars['Float'],
  volume: Scalars['Float'],
};

export type Broker = {
   __typename?: 'Broker',
  _id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  type: Scalars['String'],
  active: Scalars['Boolean'],
};


export type EquityQuote = {
   __typename?: 'EquityQuote',
  marginable: Scalars['Boolean'],
  shortable: Scalars['Boolean'],
  yearHigh: Scalars['Float'],
  yearLow: Scalars['Float'],
  nAV: Scalars['Float'],
  peRatio: Scalars['Float'],
  divAmount: Scalars['Float'],
  divYield: Scalars['Float'],
  divDate: Scalars['String'],
  regularMarketLastPrice: Scalars['Float'],
  regularMarketLastSize: Scalars['Float'],
  regularMarketNetChange: Scalars['Float'],
  regularMarketTradeTimeInLong: Scalars['Float'],
  regularMarketPercentChangeInDouble: Scalars['Float'],
};

export type Instrument = {
   __typename?: 'Instrument',
  assetType: AssetType,
  cusip: Scalars['String'],
  symbol: Scalars['String'],
  tdaSymbol: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  putCall?: Maybe<Scalars['String']>,
  optionSymbol?: Maybe<Scalars['String']>,
  optionTdaSymbol?: Maybe<Scalars['String']>,
  quote: Quote,
  bars: Array<Bar>,
};


export type InstrumentBarsArgs = {
  count?: Maybe<Scalars['Float']>
};

export type Mutation = {
   __typename?: 'Mutation',
  createBroker: Broker,
};


export type MutationCreateBrokerArgs = {
  type: Scalars['String']
};


export type OptionQuote = {
   __typename?: 'OptionQuote',
  openInterest: Scalars['Float'],
  moneyIntrinsicValue: Scalars['Float'],
  multiplier: Scalars['Float'],
  strikePrice: Scalars['Float'],
  contractType: Scalars['String'],
  underlying: Scalars['String'],
  expirationDay: Scalars['Float'],
  expirationMonth: Scalars['Float'],
  expirationYear: Scalars['Float'],
  daysToExpiration: Scalars['Float'],
  timeValue: Scalars['Float'],
  deliverables: Scalars['String'],
  delta: Scalars['Float'],
  gamma: Scalars['Float'],
  theta: Scalars['Float'],
  vega: Scalars['Float'],
  rho: Scalars['Float'],
  theoreticalOptionValue: Scalars['Float'],
  underlyingPrice: Scalars['Float'],
  uvExpirationType: Scalars['String'],
  lastTradingDay: Scalars['Float'],
  settlementType: Scalars['String'],
};

export type Position = {
   __typename?: 'Position',
  quantity: Scalars['Int'],
  shortQuantity: Scalars['Int'],
  longQuantity: Scalars['Int'],
  averagePrice: Scalars['Float'],
  currentDayProfitLoss: Scalars['Float'],
  currentDayProfitLossPercentage: Scalars['Float'],
  settledLongQuantity: Scalars['Int'],
  settledShortQuantity: Scalars['Int'],
  agedQuantity: Scalars['Int'],
  marketValue: Scalars['Float'],
  instrument: Instrument,
};

export type Query = {
   __typename?: 'Query',
  account: Account,
  accounts: Array<Account>,
  broker: Broker,
  brokers: Array<Broker>,
  profile: User,
  user: User,
};


export type QueryAccountArgs = {
  accountId: Scalars['ObjectId']
};


export type QueryBrokerArgs = {
  brokerId: Scalars['ObjectId']
};


export type QueryUserArgs = {
  userId: Scalars['ObjectId']
};

export type Quote = {
   __typename?: 'Quote',
  _id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  assetType: Scalars['String'],
  symbol: Scalars['String'],
  tdaSymbol: Scalars['String'],
  description: Scalars['String'],
  bidPrice: Scalars['Float'],
  bidSize: Scalars['Float'],
  bidId?: Maybe<Scalars['String']>,
  askPrice: Scalars['Float'],
  askSize: Scalars['Float'],
  askId?: Maybe<Scalars['String']>,
  lastPrice: Scalars['Float'],
  lastSize: Scalars['Float'],
  lastId?: Maybe<Scalars['String']>,
  openPrice: Scalars['Float'],
  highPrice: Scalars['Float'],
  lowPrice: Scalars['Float'],
  bidTick?: Maybe<Scalars['String']>,
  closePrice: Scalars['Float'],
  netChange: Scalars['Float'],
  totalVolume: Scalars['Float'],
  quoteTimeInLong: Scalars['Float'],
  tradeTimeInLong: Scalars['Float'],
  mark: Scalars['Float'],
  exchange: Scalars['String'],
  exchangeName: Scalars['String'],
  volatility: Scalars['Float'],
  digits: Scalars['Float'],
  securityStatus: Scalars['String'],
  netPercentChangeInDouble: Scalars['Float'],
  markChangeInDouble: Scalars['Float'],
  markPercentChangeInDouble: Scalars['Float'],
  delayed: Scalars['Boolean'],
  equityData?: Maybe<EquityQuote>,
  optionData?: Maybe<OptionQuote>,
};

export type User = {
   __typename?: 'User',
  _id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  email: Scalars['String'],
  name: Scalars['String'],
};

export type MainQueryVariables = {};


export type MainQuery = (
  { __typename?: 'Query' }
  & { accounts: Array<(
    { __typename?: 'Account' }
    & Pick<Account, '_id'>
    & { positions: Array<(
      { __typename?: 'Position' }
      & Pick<Position, 'shortQuantity' | 'longQuantity' | 'averagePrice'>
      & { instrument: (
        { __typename?: 'Instrument' }
        & Pick<Instrument, 'symbol' | 'assetType' | 'optionSymbol'>
        & { quote: (
          { __typename?: 'Quote' }
          & Pick<Quote, 'lastPrice'>
        ), bars: Array<(
          { __typename?: 'Bar' }
          & Pick<Bar, 'date' | 'open' | 'high' | 'low' | 'close' | 'volume'>
        )> }
      ) }
    )> }
  )> }
);

export type ProfileQueryVariables = {};


export type ProfileQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'email'>
  ) }
);


export const MainDocument = gql`
    query MAIN {
  accounts {
    _id
    positions {
      shortQuantity
      longQuantity
      averagePrice
      instrument {
        symbol
        assetType
        optionSymbol
        quote {
          lastPrice
        }
        bars {
          date
          open
          high
          low
          close
          volume
        }
      }
    }
  }
}
    `;

/**
 * __useMainQuery__
 *
 * To run a query within a React component, call `useMainQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainQuery({
 *   variables: {
 *   },
 * });
 */
export function useMainQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MainQuery, MainQueryVariables>) {
        return ApolloReactHooks.useQuery<MainQuery, MainQueryVariables>(MainDocument, baseOptions);
      }
export function useMainLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MainQuery, MainQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MainQuery, MainQueryVariables>(MainDocument, baseOptions);
        }
export type MainQueryHookResult = ReturnType<typeof useMainQuery>;
export type MainLazyQueryHookResult = ReturnType<typeof useMainLazyQuery>;
export type MainQueryResult = ApolloReactCommon.QueryResult<MainQuery, MainQueryVariables>;
export const ProfileDocument = gql`
    query PROFILE {
  profile {
    name
    email
  }
}
    `;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, baseOptions);
      }
export function useProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, baseOptions);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = ApolloReactCommon.QueryResult<ProfileQuery, ProfileQueryVariables>;