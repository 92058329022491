import { useState, useCallback, useLayoutEffect } from 'react'

function getDimensionObject<T extends HTMLElement>(node: T): DOMRect {
  const rect = node.getBoundingClientRect()

  if ('toJSON' in rect) {
    return rect.toJSON()
  } else {
    return {
      toJSON: () => {},
      width: rect.width,
      height: rect.height,
      top: rect.top,
      left: rect.left,
      x: rect.left,
      y: rect.top,
      right: rect.right,
      bottom: rect.bottom,
    }
  }
}

export default function useBounds<T extends HTMLElement>({
  updateOnResize = true,
  updateOnScroll = true,
} = {}): [DOMRect | {}, (node: T) => void, T?] {
  const [dimensions, setDimensions] = useState({})
  const [node, setNode] = useState<T>()

  const ref = useCallback((node: T) => {
    setNode(node)
  }, [])

  useLayoutEffect(() => {
    if (node) {
      const measure = (): number =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node)),
        )
      measure()

      updateOnResize && window.addEventListener('resize', measure)
      updateOnScroll && window.addEventListener('scroll', measure)

      return () => {
        updateOnResize && window.removeEventListener('resize', measure)
        updateOnScroll && window.removeEventListener('scroll', measure)
      }
    }
  }, [node, updateOnResize, updateOnScroll])

  return [dimensions, ref, node]
}
